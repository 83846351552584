import React from "react"
import Seo from "../components/seo"
import Trusted from "../components/trusted"
import Contacts from "../components/contacts"
import Glance from "../components/glance"
import Slider from "../components/slider"
import { useState, useContext } from "react"
import TryPdf from "../components/shared/TryPdf"
import { GlobalAuthContext } from "../Contexts/GlobalAuthContext"
import NewFooter from "../components/shared/NewFooter"
const FeaturesPage = () => {
  //global context
  const { setScroll } = useContext(GlobalAuthContext)
  const [myPopUp, setMyPopUp] = useState("hide")

  //close popup function
  function closePopup() {
    setMyPopUp("hide")
    setScroll("hide")
  }
  //open popup function
  function openPopup(e) {
    e.preventDefault()
    setMyPopUp("show")
    setScroll("show")
  }

  return (
    <>
      <Seo title="Features" />
      <Glance open={openPopup} location={""}></Glance>
      <Slider></Slider>
      <Trusted></Trusted>
      <NewFooter></NewFooter>
      {myPopUp === "show" && <TryPdf close={closePopup} />}
    </>
  )
}

export default FeaturesPage
